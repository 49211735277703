import React from 'react';
import './App.css';
// @ts-ignore
import glitch from 'glitch-canvas';
// @ts-ignore
import html2canvas from 'html2canvas-webpack';
import 'font-awesome/css/font-awesome.css';

class App extends React.Component<{}, { glitchedImage: null|string }> {
  constructor (props: { }) {
    super(props)
    this.state = {glitchedImage: null};
    this.glitch = this.glitch.bind(this);

    if (document.location.hostname !== 'islamov.ai' || document.location.protocol !== 'https:') {
      window.location.href = 'https://islamov.ai'
    }

    setTimeout(() => {
      this.glitch(2);
      setInterval(() => this.glitch(2), 5000);
    }, 1000);
  }

  rand (max: number) {
    return Math.floor(Math.random() * max) + 1;
  }

  glitch (count: number) {
    if (!count) {
      count = 2;
    }
    const self = this;

    html2canvas(document.getElementById('container'))
      .then((canvas: HTMLCanvasElement) => {
          //console.log('got canvas');
          const img = new Image();
          img.src = canvas.toDataURL();
          //console.log('got img');
          img.onload = () => {
            //console.log('img loaded');
            glitch( { amount: self.rand(99), seed: self.rand(99), quality: self.rand(99), iterations: self.rand(10) } )
              .fromImage( img )
              .toDataURL()
              .then( function( dataURL: string ) {
                self.setState({glitchedImage: dataURL});
                //console.log(self.state);

                count--;
                if (count == 0) {
                  setTimeout(() => {
                    self.setState({glitchedImage: null});
                  }, 150);
                } else {
                  setTimeout(() => {
                    self.setState({glitchedImage: null});
                    self.glitch(count);
                  }, 75);
                }
              });
          }
        },
        (error: Error) => {
          console.log(error);
        });
  }

  render() {
    return (
      <div className="container" id="container">
        { this.state.glitchedImage && (<img alt="" className="glitch" src={this.state.glitchedImage}/>)}
        <div id="app-header" className="header">
          <h2>Hello</h2>
        </div>

        <p className="links">
          <a href="https://www.linkedin.com/in/arthurislamov/"><i className="fa fa-linkedin"/></a>
          <a href="mailto:arthur@islamov.ai"><i className="fa fa-envelope"/></a>
          <a href="https://github.com/dakenf"><i className="fa fa-github"/></a>
          <a href="https://www.toptal.com/resume/arthur-islamov"><i className="fa fa-briefcase"/></a>
        </p>

      </div>
    );
  }
}


export default App;
